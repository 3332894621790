import moment from 'moment'
import ajax from '@/composables/useHttpCommon'

// Generate a user preferences property key to save the "Items per page" for a specific view
export const pageSizeKey = (id) => (`pageSize-${id}`)

export const common = {
	props: {
        id: {
          type: String
        },
        page: {
          type: String,
          default: '',
        },
        pageData: {
          type: Object
        }
    },
    computed: {
      jwtToken: function() {
        return 'Bearer ' + localStorage.getItem('access_token')
      }
    },    
	methods: {
		storeRecent(item, value, uniqueKey) {
			if (value) {
				let recentItems = JSON.parse(localStorage.getItem('recentItems'))
				if (!recentItems) {
					recentItems = { [value]: [] };
				}
				if (!recentItems[value]) {
					recentItems[value] = []
				}
				if (uniqueKey) {
					// if uniqueKey has been provided, make sure any existing items that have the same
					// value for uniqueKey as the incoming item are removed
					recentItems[value] = recentItems[value].filter(function( obj ) {
						return obj[uniqueKey] !== item[uniqueKey];
					});
				}
				recentItems[value].unshift(item)
				// limit recent items to 10 for now
				recentItems[value] = recentItems[value].slice(0, 9)
				localStorage.setItem('recentItems', JSON.stringify(recentItems))
			}
		},
		loadRecent(value, reverse=false) {
			if (value) {
				let recentItems = JSON.parse(localStorage.getItem('recentItems'))
				if (recentItems && recentItems[value]) {
					if (reverse) {
						return recentItems[value].reverse()
					} else {
						return recentItems[value]
					}
				}
			}
			return []
		},
		dialog(title, message, behavior) {
			this.$emit('dialog', title, message, behavior)
		},
		error(stackTrace) {
			this.$emit('error', stackTrace)
		},
		// icon
		// 0 = skip any icon output all together
		// 1 = just return the icon output if there is any
		// 2 = add icon onto the data display output
		display(header, cell, icon=1) {
			var display_cell = ""
			if (this.showIf(header, cell)) {
				var iconDisplay = ""
				if (header.icon) {
					// maybe this checking for key should be done across all cell checks?
					let cell_value;
					if (typeof cell[header.value] === 'object' && cell[header.value] !== null) {
						if (typeof header.attribute !== "undefined") {
							cell_value = cell[header.value][header.attribute]
						}
					} else {
						cell_value = cell[header.value]
					}
					if (icon == 1) {
						return this.showIcon(header.icon, cell_value)
					} else if (icon == 2) {
						iconDisplay = "&nbsp;" + this.showIcon(header.icon, cell_value)
					}
				}
				if (header.name + '.display' in cell) {
					display_cell = cell[header.name + '.display']
				} else if (header.value + '.display' in cell) {
					display_cell =  cell[header.value + '.display']    
				} else if (header.value in cell) {
					display_cell =  cell[header.value]
					if (display_cell && header.name && Object.prototype.hasOwnProperty.call(display_cell, header.name)) {
						// Render data which is a child object of display_cell
						display_cell = display_cell[header.name];
					}
				}
				if (header.convert) {
					display_cell = this.convert(display_cell, header.convert)
				}
				if (iconDisplay) {
					return iconDisplay + display_cell
				} else {
					return display_cell
				}
			}
		},
		showIcon(icon, index) {
			if (typeof icon == 'object') {
				if (icon[index]) {
					return icon[index]
				}
			} else {
				if (index) {
					return icon
				}
			}
		},
		logit(data) {
			console.log(JSON.stringify(data, null, 4))
		},
		range(start, stop, step) {
			return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step))
		},
        getDataFromURLs(urls) {
			return Promise.allSettled(urls.map(l => ajax.get(l), {}))
				.then(resp => {
                    // only return the data for all of the 'fulfilled' responses
					return resp.filter(obj=>{return obj.status === 'fulfilled'}).map(a => a.value)
				})
        },
		// generic method used to convert data into a different form
		convert(data, what) {
			if (typeof what !== 'undefined') {
				//
				// define how to convert the data
				//
				var from, to;
				if (typeof what == 'object') {
					if (typeof what['from'] !== 'undefined') {
						from = what['from']
					}
					if (typeof what['to'] !== 'undefined') {
						to = what['to']
					}
				} else if (what.indexOf('object:') == 0) {
					var object = what.replace("object:", "")
					return data[object]
				} else if (what == 'currency') {
					let price = new Intl.NumberFormat('en-Nz',
						{
							style: 'currency',
							currency: 'NZD',
						})
					return price.format(data);
				} else {
					from = what
				}
				//
				// return converted data according to data type
				//
				if (typeof data === "number") {
					if (from == 'timestamp') {
						if (data > 1300000) {
							if (to == 'date') {
								return moment.unix(data).format('DD-MM-YYYY')
							} else if (to == 'datetime') {
								return moment.unix(data).format('DD-MM-YYYY h:mm:ss a')
							} else {
								return moment.unix(data).format('dddd, MMMM Do, YYYY h:mm:ss a')
							}
						} else {
							return ''
						}
					}
				}
				if (typeof data === "string" && data.trim().length !== 0) {
					if (from == 'date') {
						let localDate = new Date(data);
						if (to === 'date') {
							return moment(localDate).format('dddd, MMMM Do, YYYY')
						} else if (to === 'isodate') {
							return moment(localDate).format('YYYY-MM-DD')
						} else {
							return moment(localDate).format('DD-MM-YYYY')
						}
					} else if (from === 'datetime') {
						let localDate = new Date(data);
						if (to == 'date') {
							return moment(localDate).format('DD-MM-YYYY')
						} else if (to == 'human') {
							return moment(localDate).format('dddd, MMMM Do, YYYY h:mm:ss a')	
						} else {
							const formattedDate = moment(localDate).format('DD-MM-YYYY h:mm:ss a');
							if (formattedDate === 'Invalid date') {
								return data
							}
							return formattedDate
						}
					} else if (from == 'capitals') {
						return this.capitalizeFirstLetter(data)
					}
				}
			}
			return data
		},
		capitalizeFirstLetter(string) {
			return string.replace(/_/g, " ").toLowerCase().replace(/^(.)|\s(.)/g, ($1) => $1.toUpperCase()) 
		},
		// parse a url, replace {word} elements and add query parameters
		url(url, replace, parameters) {
			if (url instanceof Array) {
				var new_urls = [];
				for (var a_url in url) {
					new_urls.push(a_url.replace(/\{\w+\}/, replace))
				}
				return new_urls
			}
			if (typeof url === 'string' || url instanceof String) {
				var return_url
				if (replace) {
					if (typeof replace === 'object') {
						for (var name in replace) {
							if (typeof name !== 'object') {
								var re = new RegExp("{" + name + "}", "g");
								url = url.replace(re, replace[name]), "http://dummy.com"
							}
						}
						return_url = new URL(url, "http://dummy.com")
					} else {
						return_url = new URL(url.replace(/\{\w+\}/, replace), "http://dummy.com")
					}
				} else {
					return_url = new URL(url.replace(/\{\w+\}/, ""), "http://dummy.com")
				}
				if (typeof parameters === 'object') {  
					for (var a_param in parameters) {
						return_url.searchParams.append(a_param, parameters[a_param])
					}
				}
				// return with URL.origin if we received a relative url
				var r = new RegExp('^(?:[a-z+]+:)?//', 'i');
				if (r.test(url)) {
					return return_url.toString()
				} else {
					return return_url.toString().substring(return_url.origin.length)
				}
			}
		},
		// a generic method to direct the user to a new path
		go(path, file = false) {
			if (path.match(/^(http(s)?|ftp):\/\//)) {
				if (file) {
					ajax.get(path, {responseType: 'blob'})
						.then((response) => {
							const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = downloadUrl;
							link.setAttribute('download', file); //any other extension
							document.body.appendChild(link);
							link.click();
							link.remove();
							this.$emit('dialog', 'Download of ' + file + ' Complete!')
					})
						.catch(e => {
							this.$emit('dialog', 'Error downloading file')
							this.$emit('error', e)
					})
				} else {
					window.open(path, '_blank');
				}
			} else {
				this.$router.push(path)
			}
		},
		replaceParams(dataWithParams, sourceData) {
			const re = new RegExp('<(.+?)>', 'g')
            const matches = [...dataWithParams.matchAll(re)];
			for (const match of matches) {
				if (sourceData[match[1]]) {
					dataWithParams = dataWithParams.replace(match[0], sourceData[ match[1]])
				}
			}
			return dataWithParams
		},
		showIf(item, data) {
			if (typeof item.showIf == 'object') {
				// value must be equal to
				if (item.showIf.operator == 'equalTo') {
					if (item.showIf.value !== data[item.showIf.name]) {
						return false
					}
				// value must not be equal to
				} else if (item.showIf.operator == 'notEqualTo') {
					if (item.showIf.value === data[item.showIf.name]) {
						return false
					}
				// value must not be empty or unset
				} else if (item.showIf.operator == 'notEmpty') {
					let nameList = Array()
					if (!Array.isArray(item.showIf.name)) {
						nameList.push(item.showIf.name)
					} else {
						nameList = item.showIf.name
					}
					for (let name of nameList) {
						if (!data[name]) {
							return false
						}
						if (Array.isArray(data[name]) && data[name].length === 0) {
							return false
						}
					}
				// value must be empty or unset
				} else if (item.showIf.operator == 'empty') {
					let nameList = Array()
					if (!Array.isArray(item.showIf.name)) {
						nameList.push(item.showIf.name)
					} else {
						nameList = item.showIf.name
					}
					for (let name of nameList) {
						if (Array.isArray(data[name]) && data[name].length !== 0) {
							return false
						}
						if (data[name]) {
							return false
						}
					}
				} else {
					if (typeof data[item.showIf.name] !== "undefined") {
						// value must be one of
						if (item.showIf.operator == 'oneOf') {
							if (!item.showIf.value.includes(data[item.showIf.name])) {
								return false
							}
						}
						// value must not be one of
						if (item.showIf.operator == 'notOneOf') {
							if (item.showIf.value.includes(data[item.showIf.name])) {
								return false
							}
						}
					}
				}
			}
			return true
		}
	}
}