<template>
  <v-container fluid>
    <h5 class="text-h5 pa-2">{{ header }}</h5>
    <p v-if="description" class="text-body-1 pa-2">{{description}}</p>
    <v-card-text>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index" color="primary" rounded="xl" density="comfortable"
          @click="listItemClicked(item)">
          <template v-slot:prepend>
            <v-icon size="large">fas {{ item.icon }}</v-icon>
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card-text>

  </v-container>
</template>

<script>
import { common } from '../mixins/common'
import ajax from '@/composables/useHttpCommon';
import { Buffer } from "buffer"
export default {
  mixins: [common],
  data: function () {
    return {
      data: {},
      items: [],
      header: '',
      description: '',
    }
  },
  props: {
    id: {
      type: String
    },
    list: {
      type: Object
    }
  },
  mounted: function () {
    if (typeof this.list.url == "string") {
      let url = this.url(this.list.url, this.id)
      this.getListData(url)
    }
    if (typeof this.list.items == "object") {
      this.header = this.list.items.find((item) => item.header).header || '';
      this.description = this.list.items.find((item) => item.description)?.description || '';
      this.items = this.list.items.filter((item) => !item.header)
    }
  },
  methods: {
    getListData(url) {
      ajax.get(url)
        .then(response => {
          this.data = response.data
          this.$emit('data', this.data)
        })
        .catch(e => {
          this.error(e)
        })
    },
    getItemData(url, item, value) {
      ajax.get(url)
        .then(response => {
          this.dummy(item, value, response.data)
        })
        .catch(e => {
          this.error(e)
        })
    },
    showListItem(item, data) {
      return this.showIf(item, data)
    },
    listItemClicked(item) {
      // make sure the page id is in the list data
      // so that it can be used in url building
      // note the if the page id is different than
      // the list data id, then the list data id
      // is getting replaced!  This is problematic
      this.data['id'] = this.id
      if (typeof item.path == 'object') {
        if (typeof item.path.url != 'undefined') {
          let url = this.url(item.path.url, this.id)
          this.getItemData(url, item, this.id);
        } else {
          this.dummy(item, this.id)
        }
      } else {
        // link/launch/go off to a new page when the button is clicked
        this.go(this.url(item.path, this.data))
      }
    },
    dummy(item, value, data) {
      if (typeof item.path.get !== "undefined") {
        // a get request with parameters on the button push
        let url = this.url(item.path.get, value)
        ajax.get(url)
          .then(response => {
            if (typeof response.data.message != "undefined") {
              this.$emit('dialog', "Success!", response.data.message)
            } else if (typeof response.data != "undefined") {
              this.$emit('dialog', "Success!", response.data)
            } else {
              this.$emit('dialog', "Success!")
            }
          })
          .catch(e => {
            this.error(e)
          })
      } else if (typeof item.path.put !== "undefined") {
        // a put request with parameters in the payload on the button push
        let url = this.url(item.path.put, value)
        let payload = {}
        if (item.path.payload) {
          payload = this.replaceParams(item.path.payload, data)
        }
        ajax.put(url, payload)
          .then(response => {
            if (typeof response.data.message != "undefined") {
              this.$emit('dialog', "Success!", response.data.message)
            } else if (typeof response.data != "undefined") {
              this.$emit('dialog', "Success!", response.data)
            } else {
              this.$emit('dialog', "Success!")
            }
          })
          .catch(e => {
            this.error(e)
          })
      } else if (typeof item.path.post !== "undefined") {
        // a post request with parameters in the payload on the button push
        let url = this.url(item.path.post, value)
        let payload = {}
        if (item.path.payload) {
          payload = this.replaceParams(item.path.payload, data)
        }
        ajax.post(url, payload, { responseType: 'arraybuffer' })
          .then(response => {
            if (response.headers['content-type'] == 'image/png') {
              // response is an image
              var imgBase64 = Buffer.from(response.data, 'binary').toString('base64')
              this.$emit('dialog', "Success!", "<img src='data:" + response.headers['content-type'] + ";base64," + imgBase64 + "' />")
            } else if (response.headers['content-type'] == 'text/html; charset=UTF-8') {
              // response is text
              var enc = new TextDecoder("utf-8");
              var arr = new Uint8Array(response.data);
              this.$emit('dialog', "Success!", enc.decode(arr))
            } else {
              this.$emit('dialog', "Success!", response.headers['content-type'])
            }
          })
          .catch(e => {
            this.error(e)
          })
      }
    }
  }
}
</script>
