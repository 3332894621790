<template>
  <v-container fluid>
      <div v-for="(customFilter) in customFilters" :key="customFilter.type">
        <BaseTableFilterDateRange v-if="customFilter.type === 'dateRange'" :config="customFilter" @update:dateRange="onFilterDateRangeUpdate" />
      </div>
      <v-data-table-server
        :headers="tableHeaders"
        :items="items"
        :loading="loading"
        :items-per-page="pagination.pageSize"
        :sort-by="sortBy"
        @update:options="handleTableOptionsUpdate"
        fixed-header
        :items-length="total"
        class="elevation-1"
        :show-expand="!!childTableHeaders"
      >
        <template v-slot:expanded-row="{ columns, item  }">
          <td :colspan="columns.length" class="bg-grey-lighten-5">
            <v-card class="ma-8">
              <!-- 
                  If the child data is an array (e.g. employees list) then handle the data like this:
              -->
              <v-data-table
                v-if="childTableHeaders?.length === 1 && childTableHeaders[0]?.value[0]?.length > 0"
                class="bg-grey-lighten-4"
                :items="item[childTableHeaders[0].value]"
              />
            <!--
                Otherwise, the child data is an object (e.g. employee details) then as follows
            -->
              <v-data-table
                v-else
                class="bg-grey-lighten-4"
                :headers="childTableHeaders"
                :items="[item]"
              >
              <template v-slot:bottom>
                <!-- empty so we don't show pagination -->
              </template>
            </v-data-table>
            </v-card>
          </td>
        </template>

        <template v-slot:top>
          <h4 class="text-h4 pa-2">{{ label }}</h4>
          <v-container v-if="controls" fluid class="pa-4">
            <v-row align="center" justify="space-between">
              <v-col cols="12" sm="12" md="2">
                <v-text-field v-model="pagination.search" prepend-inner-icon="fas fa-search" label="Search"
                  single-line hide-details variant="outlined" density="comfortable"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" class="d-flex justify-center">
                <v-pagination v-model="pagination.page" :length="pages" :total-visible="7"
                  @update:model-value="changePage" rounded="circle"></v-pagination>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="d-flex justify-end align-center">
                <v-btn v-if="!loading" color="primary" size="large" :variant="showCachedDataButton ? 'elevated' : 'text'" :disabled="!showCachedDataButton" @click="onUpdateDataSource" :prepend-icon="showCachedDataButton ? 'fas fa-redo' : 'fas fa-list'" class="mr-2">{{ showCachedDataButton ? total + ' Items (from cache)' : total + ' Items Total' }}</v-btn>
                <v-select v-model="pagination.pageSize" :items="itemsPerPageMenu" label="Items Per Page" density="comfortable" variant="outlined" hide-details class="mr-2" style="max-width: 150px;" :disabled="loading || showCachedDataButton"></v-select>
              </v-col>

            </v-row>
          </v-container>
        </template>

        <template v-slot:loading>
          <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
        </template>
        <template v-slot:bottom>
          <v-container v-if="controls" fluid class="pa-4">
            <v-row align="center" justify="space-between">
              <v-col cols="12" sm="12" md="3"></v-col>
              <v-col cols="12" sm="12" md="3" class="d-flex justify-center">
                <v-pagination v-model="pagination.page" :length="pages" :total-visible="7"
                  @update:model-value="changePage" rounded="circle"></v-pagination>
              </v-col>
              <v-col cols="12" sm="12" md="3" class="d-flex justify-end align-center">
                <v-select v-model="pagination.pageSize" :items="itemsPerPageMenu" label="Items Per Page"
                  density="comfortable" variant="outlined" hide-details class="mr-2"
                  style="max-width: 150px;" :disabled="loading || showCachedDataButton"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item="{ item: itemProps, isExpanded, internalItem, toggleExpand }">
          <tr>
            <td v-for="(header, index) in tableHeaders" :key="index">
              <span v-if="header.value === 'data-table-expand'">
                <v-btn
                  :icon="isExpanded(internalItem) ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
                  variant="plain"
                  @click="toggleExpand(internalItem)"
                />
              </span>
              <span v-else-if="header.route">
                <a @click="recent(itemProps); followRoute(header, itemProps)" class="text-primary-lighten-2 custom-icon">
                  <span v-if="header.icon">
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-icon class="text-primary-lighten-2 custom-icon" v-bind="props" :icon="'fas ' + showIcon(header.icon, itemProps[header.value])"></v-icon>
                      </template>
                      <span style="text-transform: capitalize;">{{itemProps[header.value]}}</span>
                    </v-tooltip>
                  </span>
                  <span v-else>{{ display(header, itemProps) }}</span>
                </a>
              </span>
              <span v-else-if="header.delete">
                <v-icon class="text-primary-lighten-2 custom-icon" v-if="header.icon"
                  @click="dialog(header.delete, itemProps[header.value], 'delete')">fas {{ header.icon }}</v-icon>
                <span v-else>{{ display(header, itemProps) }}</span>
              </span>
              <span v-else-if="header.update">
                <v-icon class="text-primary-lighten-2 custom-icon" v-if="header.icon"
                  @click="dialog(header.update, itemProps[header.value], 'update')">fas {{ header.icon }}</v-icon>
                <span v-else>{{ display(header, itemProps) }}</span>
              </span>
              <span v-else>
                <span v-if="header.icon">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-icon class="text-primary-lighten-2 custom-icon" v-bind="props" :icon="'fas ' + showIcon(header.icon, itemProps[header.value])"></v-icon>
                    </template>
                    <span style="text-transform: capitalize;">{{itemProps[header.value]}}</span>
                  </v-tooltip>
                </span>
                <span v-else>{{ display(header, itemProps) }}</span>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table-server>

    <v-btn icon="fas fa-plus" size="x-large" color="primary" v-show="addButtonUrl" @click="go(addButtonUrl)" style="margin-top: 1rem"></v-btn>
  </v-container>
</template>

<script>
import { common, pageSizeKey } from '@/mixins/common'
import ajax from '@/composables/useHttpCommon';
import _ from 'lodash';
import { useUserPreferences } from '@/composables/userPreferences'

export default {
  mixins: [common],
  data: function () {
    const { preferences } = useUserPreferences()
    return {
      isInitialized: false, // flag to check if the component is initialized
      items: [],
      loading: true,
      itemsPerPageMenu: [5, 10, 25, 50, { title: 'All', value: -1 }],
      sortBy: [],
      pagination: {
        page: 1,
        pageSize: preferences.value[pageSizeKey(this.id)] ? preferences.value[pageSizeKey(this.id)] : preferences.value.pageSize,
        search: ''
      },
    }
  },
  methods: {
    onFilterDateRangeUpdate(filter) {
      this.loading = true;
      this.$emit('updateDateRange', filter);
    },
    handleTableOptionsUpdate(options) {
      const { updatePreference } = useUserPreferences()
      if (!this.isInitialized) {
        // Prevent the first update from being processed as we want to show data from cache
        this.isInitialized = true;
        return;
      }
      this.pagination.page = options.page;
      this.pagination.pageSize = options.itemsPerPage;
      updatePreference(pageSizeKey(this.id), this.pagination.pageSize)
      this.sortBy = Array.isArray(options.sortBy) ? options.sortBy : [];
      this.onUpdateDataSource();
    },
    onUpdateDataSource: function () {
      if ('onUpdateDataSource' in this.$attrs) {
        this.loading = true;
        const paginationData = {
          ...this.pagination,
          sortBy: this.sortBy
        };
        this.$emit('updateDataSource', paginationData);
      }
    },
    recent(item) {
      var uniqueKeyObj = this.tableHeaders.find(obj => {
        return obj.uniqueKey === true
      })
      let uniqueKey;
      if (uniqueKeyObj) {
        uniqueKey = uniqueKeyObj.value
      }
      this.storeRecent(item, this.saveToRecent, uniqueKey)
    },
    followRoute(header, item) {
      if (header.id) {
        this.go(this.url(header.route, item[header.id]))
      } else if (header.attribute) {
        /**
         * As of Vue/Vuetify 3, `key` is a required header property name that _must_ exist to enable table sorting.
         * Unfortunately this value, `key`, is sometimes used by Chomp as a pointer to a value in an object which 
         * should be used as a navigation identifier (if `id` is not present in the table header configuration (see config.js)).
         * 
         * An additional check has been added here to make sure the value which the pointer links to exists in the target object.
         * If it doesn't, fall-though to the default behavior from Vue 2 days.
         * 
         * See: tableHeaders() below for more details.
         * TODO: refactor for a more robust solution
         */
        if (item[header.value][header.attribute]) {
          this.go(this.url(header.route, item[header.value][header.attribute]))
        } else {
          this.go(this.url(header.route, item))  
        }
      } else {
        this.go(this.url(header.route, item))
      }
    },
    changePage: function (event) {
      this.loading = true
      if (event) {
        this.pagination.page = event
        this.onUpdateDataSource()
      }
    },
    dialog(url, data, action) {
      var func
      if (action == 'delete') {
        func = this.del
      } else {
        func = this.update
      }
      this.$emit('dialog', action + ' Item', 'Are you sure you would like to ' + action + ' this item?', func.bind(this, url, data))
    },
    del(url, data) {
      ajax.delete(this.url(url, data))
        .then(response => {
          console.log(response.data)
          this.$emit('dialog', 'Deleted!', 'Deleted!', true)
        })
        .catch(e => {
          this.$emit('error', e)
        })
    },
    update(url, data) {
      ajax.patch(this.url(url, data))
        .then(response => {
          console.log(response.data)
          this.$emit('dialog', 'Updated!', 'Updated!', true)
        })
        .catch(e => {
          this.$emit('error', e)
        })
    }
  },
  computed: {
    pages: function () {
      if (this.total && this.pagination.pageSize) {
        return Math.ceil(this.total / this.pagination.pageSize)
      } else if (this.items.length) {
        return Math.ceil(this.items.length / this.pagination.pageSize)
      } else {
        return 1
      }
    },
    tableHeaders: function () {
      var val = this.headers.map((header) => ({
        ...header,
        title: header.text || header.title,
        /**
         * Comment out `key` to disable table sorting and fallback to Vue 2 behavior
         */
        key: header.value, 
      }));
      if (this.deleteButtonUrl) {
        val.push({
          'title': 'Delete',
          'value': 'id',
          'icon': 'fa-trash',
          'delete': this.deleteButtonUrl,
          id: 'delete-' + new Date().getTime()
        });
      }
      return val;
    },
    childTableHeaders: function() {
      return this.headers.find(h => h.value === 'data-table-expand')?.data

    }
  },
  props: {
    headers: {
      type: Array
    },
    label: {
      type: String
    },
    rows: {
      type: [Array, Number, Object, String]
    },
    total: {
      type: Number
    },
    addButtonUrl: {
      type: String
    },
    deleteButtonUrl: {
      type: String
    },
    controls: {
      type: Boolean,
      default: true
    },
    saveToRecent: {
      type: String
    },
    showCachedDataButton: {
      type: Boolean,
      default: true
    },
    customFilters: {
      type: Array
    },
    id: {
      type: String
    }
  },
  watch: {
    'pagination.search': {
      handler: _.debounce(function () {
        this.onUpdateDataSource();
      }, 300),
      deep: true
    },
    'pagination.pageSize': {
      handler(_newValue) {
        this.onUpdateDataSource();
      }
    },
    rows: {
      handler(val) {
        // console.log('ROWS changed')
        if (Array.isArray(val) && val.length > 0) {
          if (!this.controls) {
            // without controls we need one long table
            this.pagination.pageSize = this.rows.length;
          }

          // if our page number is past our total pages
          this.pagination.page = Math.min(this.pagination.page, this.pages);

          this.items = val;
          this.loading = false;
          // console.log('There is data, either from a local cache or API, so showing that');
        } else if (this.showCachedDataButton) {
          // console.log('Data was passed in but this is empty and this is not cached, so requesting to update cache')
          this.onUpdateDataSource();
        } else if (Array.isArray(val) && val.length === 0) {
          // There's just no data
          // console.log('There is NO data to display', val);
          this.loading = false;
          this.items = [];
        } else {
          // console.log('There is NO data from cache or API', val, this.loading);
          // this.loading = false;
          // this.items = [];
        }
      }
    }
  }
}
</script>

<style scoped>
.custom-icon {
  cursor: pointer;
}

.custom-icon:hover {
  opacity: 0.5;
}

.position-relative {
  position: relative;
}
.v-table {
  font-size: 1.1rem;
}

.v-data-table td:first-child,
.v-data-table th:first-child {
  /* white-space: nowrap; */
  min-width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>