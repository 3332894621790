<template>
  <v-container
  fluid>
  <h5 class="text-h5 pa-2">{{table.title}}</h5>
  <p v-if="table.description" class="text-body-1 pa-2">{{table.description}}</p>
  <BaseTable
    :headers="headers"
    :rows="data"
    :addButtonUrl="url(table.add, id, { 'add': true })"
    :deleteButtonUrl="table.delete"
    :total="totalItems"
    :saveToRecent="saveToRecent"
    :showCachedDataButton="isDataFromCache"
    :customFilters="table.customFilters"
    :id="id"
    @updateDataSource="updateDataSource"
    @updateDateRange="onUpdateFilterDateRange"
    @dialog="dialog"
    @error="error">
  </BaseTable>
</v-container>
</template>

<script>
  import { common, pageSizeKey } from '@/mixins/common'
  import ajax from '@/composables/useHttpCommon';
  import { useUserPreferences } from '@/composables/userPreferences'

  const { preferences } = useUserPreferences()

  export default {
    mixins: [ common ],
    data: () => ({
      data: [],
      totalItems: 0,
      pagination: {
        page: 1,
        pageSize: preferences.value.pageSize,
        search: '',
      },
      filterDateRange: {},
      lookupData: [],
      headers: [],
      saveToRecent: null,
      isDataFromCache: false,
    }),
    props: {
      id: {
          type: String
      },
      title: {
        type: String
      },
      table: {
          type: Object
      }
    },
    methods: {
      onUpdateFilterDateRange(filters) {
        this.filterDateRange = filters;
        this.loadTable();
      },
      updateDataSource(options) {
        this.pagination = {
          ...options,
          sortBy: Array.isArray(options.sortBy) ? options.sortBy : []
        };
        this.loadTable();
      },
      findLookupValue(index, lookupKey, lookupValue, returnKey) {
        if (typeof this.lookupData[index] !== "undefined" && typeof lookupKey !== "undefined" && typeof returnKey !== "undefined") {
          try {
            var result = this.lookupData[index].filter(obj => {
              if (obj != null) {
                return obj[lookupKey] === lookupValue
              }
            })
          } catch (err) {
            console.log(err)
            return lookupValue
          }
          if (typeof result[0] !== "undefined") {
            return result[0][returnKey]
          }
        }
        return lookupValue
      },
      buildLookupTables(headers) {
        var lookupDataConfig = []
        var lookupDataPrefetchUrls = []
        var lookupDataPostfetchUrls = []
        for (let index in headers) {
          if (typeof headers[index].lookup !== "undefined") {
            let headerLookup = headers[index].lookup
            if (typeof headerLookup.url !== "undefined") {
              if (typeof headerLookup.search !== "undefined") {
                if (headers[index].lookup.prefetch == true) {
                  // this has been set to be a prefetch url, we should do what we are told
                  lookupDataPrefetchUrls[index] = this.url(headerLookup.url, this.id)
                } else {
                  headers[index].lookup.value = headers[index].value
                  var re = /\{\w+\}/
                  // prefetch urls do not require value replacement
                  if (!re.test(headerLookup.url)) {
                    headers[index].lookup.prefetch = true
                    lookupDataPrefetchUrls[index] = headerLookup.url
                  }
                  // postfetch urls require value replacements from the row in order to be used
                  if (re.test(headers[index].lookup.url)) {
                    headers[index].lookup.postfetch = true
                    lookupDataPostfetchUrls[index] = headerLookup.url
                  }
                }
                lookupDataConfig[index] = headers[index].lookup
              }
            }
          }
        }
        return { config: lookupDataConfig, prefetch: lookupDataPrefetchUrls, postfetch: lookupDataPostfetchUrls };
      },
      transformData(data) {
        // first: load table data
        // second: load lookup data that does not require the table data to lookup
        // third: load lookup data the requires the table data to lookup 
        // fourth: transform data using lookupData
        var lookupTables = this.buildLookupTables(this.table.headers)

        var promises = []
        
        // load prefetch into lookup data
        if (lookupTables.prefetch.length > 0) {
          promises.push(new Promise( (resolve, reject) => {
            this.getDataFromURLs(lookupTables.prefetch).then( (results) => {
              // load prefetch into lookup data
              for (let index in lookupTables.config) {
                if (lookupTables.config[index].prefetch) {
                  // this.$set(this.lookupData, index, results[index].data)
                  this.lookupData[index] = results[index].data;
                }
              }
              resolve()
            }, (error) => {
              reject(error)
            })
          }))
        }

        // load postfetch into lookup data
        if (lookupTables.postfetch.length > 0) {
          promises.push( new Promise( (resolve, reject) => {
            var postfetchPromises = []
            for (let index in lookupTables.config) {  
              if (lookupTables.config[index].postfetch) {
                var postFetchUrls = []
                for (let line in data) {
                  // skip looking up items which have a null value
                  if (data[line][lookupTables.config[index].value] !== null) {
                    let url = this.url(lookupTables.config[index].url, data[line][lookupTables.config[index].value])
                    if (!postFetchUrls.includes(url)) {
                      postFetchUrls.push(url)
                    }
                  }
                }
                postfetchPromises.push( new Promise( (resolve, reject) => {
                  this.getDataFromURLs(postFetchUrls).then( (results) => {
                    var lookupDataArray = []
                    for (let line in data) {
                      if (typeof results[line] !== "undefined") {
                        lookupDataArray.push(results[line].data)
                      }
                    }
                    // this.$set(this.lookupData, index, lookupDataArray)
                    this.lookupData[index] = lookupDataArray;
                    resolve()
                  }, (error) => {
                    reject(error)
                  })
                }))
              }
            }

            Promise.all(postfetchPromises).then( () => {
              resolve()
            }, (error) => {
              reject(error)
            })
          }))
        }

        // replace and convert values for table
        Promise.all(promises).then( () => {
          for (let index in this.table.headers) {
            let headerItem = this.table.headers[index]
            for (let line in data) {
              // if the value we are looking for is by key from an object in the response
              let headerItemValue = ''
              if (typeof data[line][headerItem.value] == 'object' && typeof headerItem.key !== 'undefined') {
                if (data[line][headerItem.value] && data[line][headerItem.value][headerItem.key]) {
                  headerItemValue = data[line][headerItem.value][headerItem.key]
                } else {
                  headerItemValue = ''
                }
              } else {
                headerItemValue = data[line][headerItem.value]
              }
              // make sure information displayed is correctly named
              let headerItemName = headerItem.value + '.display'
              if (typeof headerItem.name !== 'undefined') {
                headerItemName = headerItem.name + '.display'
              }
              // now that we know what our data is and how it is called
              // let's lookup and convert the value as required
              if (typeof headerItem.lookup !== 'undefined') {
                // data lookup defined, replace with a lookup value
                // this.$set(data[line], headerItemName, this.convert(this.findLookupValue(index, headerItem.lookup.search, headerItemValue, headerItem.lookup.return), headerItem.convert))
                this.data[line][headerItemName] = this.convert(this.findLookupValue(index, headerItem.lookup.search, headerItemValue, headerItem.lookup.return), headerItem.convert);
              } else {
                // no lookup defined, convert the value for display if neccesary

                // console.log('data[line]', data[line])
                // console.log('headerItemName', headerItemName)
                // console.log('this.convert(headerItemValue, headerItem.convert)', this.convert(headerItemValue, headerItem.convert))
                // this.$set(data[line], headerItemName, this.convert(headerItemValue, headerItem.convert))

                /**
                 * TODO: I don't think we need the following line as our data is being double converted somewhere along the line
                 * SEE: https://trello.com/c/5SHqGr6E/224-vue3-migration-some-dates-are-showing-as-invalid
                 */
                // this.data[line][headerItemName] = this.convert(headerItemValue, headerItem.convert);
              }
            }
          }
        }, (e) => {
          this.$emit('error', e)
        })
        return data
      },
      loadTable() {
        // include URI parameters;
        let parameters = this.$route.query;
        parameters.id = this.id;
        if (typeof this.table.url !== 'undefined') {
          var url = new URL(this.url(this.table.url, parameters));
          if (this.pagination.search) {
            url.searchParams.set('filter', this.pagination.search);
          }
          if (typeof this.pagination.page !== 'undefined') {
            url.searchParams.set('page', this.pagination.page);
          }
          if (typeof this.pagination.pageSize !== 'undefined') {
            url.searchParams.set('size', this.pagination.pageSize);
          }
          if (this.pagination.sortBy && Array.isArray(this.pagination.sortBy) && this.pagination.sortBy.length > 0) {
            const sortItem = this.pagination.sortBy[0];
            if (sortItem && sortItem.key) {
              url.searchParams.set('orderBy', sortItem.key);
              url.searchParams.set('direction', sortItem.order || 'asc');
            }
          }

          if (Object.keys(this.filterDateRange).length > 0) {
            for (const [key, value] of Object.entries(this.filterDateRange)) {
              url.searchParams.set(key, value);
            }
          }
          ajax.get(decodeURI(url.toString()), {})
            .then(response => {
              if (Array.isArray(response.data)) {
                this.$emit('data', response.data)
                this.totalItems = response.data.length
              } else if (typeof response.data == 'object') {
                this.$emit('data', response.data)
                if (Object.prototype.hasOwnProperty.call(response.data, 'total')) {
                  this.totalItems = response.data.total;
                }
              } else {
                console.log("table data response is weird")
                console.log(response.data)
              }
              if (typeof this.table.dataKey !== 'undefined') {
                this.data = this.transformData(response.data[this.table.dataKey])
              } else if (typeof response.data['data'] !== 'undefined') {
                this.data = this.transformData(response.data['data'])
              } else {
                this.data = this.transformData(response.data)
              }
              this.isDataFromCache = false;
          })
            .catch(e => {
              this.$emit('error', e)
          })
        }
      },
      getData() {
        //
        // clear out old data
        // WHY????
        //
        this.data = null
        //
        // Set Table Headers
        //
        this.headers = JSON.parse(JSON.stringify(this.table.headers));
        //
        // Load Table Data
        //
        if (typeof this.table.prefill === 'undefined' || this.table.prefill) {
          this.loadTable()
        } else {
          this.saveToRecent = this.title
          this.data = this.loadRecent(this.title)
          this.totalItems = this.data.length;
          this.isDataFromCache = !!this.data;
          this.$emit('data', this.data)
        }
      }
    },
    mounted: function () {
      this.pagination.pageSize = preferences.value[pageSizeKey(this.id)] ? preferences.value[pageSizeKey(this.id)] : preferences.value.pageSize;
      this.getData()
    },
    watch: {
      '$route': {
        handler () {
          this.getData()
        }
      }
    }
  }
</script>
